@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: shabnam;
    src: url('./../assets/fonts/eot/Shabnam.eot');
    src: url('./../assets/fonts/eot/Shabnam.eot?#iefix') format('embedded-opentype'),
    url('./../assets/fonts/woff2/Shabnam.woff2') format('woff2'),
    url('./../assets/fonts/woff/Shabnam.woff') format('woff'),
    url('./../assets/fonts/ttf/Shabnam.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: shabnam;
    src: url('./../assets/fonts/eot/Shabnam-Bold.eot');
    src: url('./../assets/fonts/eot/Shabnam-Bold.eot?#iefix') format('embedded-opentype'),
    url('./../assets/fonts/woff2/Shabnam-Bold.woff2') format('woff2'),
    url('./../assets/fonts/woff/Shabnam-Bold.woff') format('woff'),
    url('./../assets/fonts/ttf/Shabnam-Bold.ttf') format('truetype');
    font-weight: bold;
}


@font-face {
    font-family: shabnam;
    src: url('./../assets/fonts/eot/Shabnam-Light.eot');
    src: url('./../assets/fonts/eot/Shabnam-Light.eot?#iefix') format('embedded-opentype'),
    url('./../assets/fonts/woff2/Shabnam-Light.woff2') format('woff2'),
    url('./../assets/fonts/woff/Shabnam-Light.woff') format('woff'),
    url('./../assets/fonts/ttf/Shabnam-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: shabnam;
    src: url('./../assets/fonts/eot/Shabnam-Medium.eot');
    src: url('./../assets/fonts/eot/Shabnam-Medium.eot?#iefix') format('embedded-opentype'),
    url('./../assets/fonts/woff2/Shabnam-Medium.woff2') format('woff2'),
    url('./../assets/fonts/woff/Shabnam-Medium.woff') format('woff'),
    url('./../assets/fonts/ttf/Shabnam-Medium.ttf') format('truetype');
    font-weight: 500;
}

/* #region global */

html {
    scroll-behavior: smooth;
}

body {
    direction: rtl;
    text-align: right;
    background-color: #ececf0;
    font-family: shabnam;
}

::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.important_item::before {
    content: '*';
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #f74455;
    margin-left: 4px;
}

button:disabled {
    background: #ebecf0;
    color: #b3bac5;
}

input:focus,
textarea:focus {
    outline: none;
}

.ltrText {
    direction: ltr;
}

.rtlText {
    direction: rtl;
}

select {
    background-position: left 0.5rem center !important;
}

/* .defaultHeadJob {
	@apply flex items-center gap-2  bg-white py-1 w-56 justify-center text-gray4 rounded-lg text-sm font-normal 
  }
.defaultHeadJob:hover{
	@apply  border border-solid border-blue2
  }
.defaultHeadJobActive{
   @apply flex items-center gap-2 bg-blue2 py-1 w-56 justify-center text-white rounded-lg text-sm font-normal 
  }
.OptionsDefault{
    @apply  flex flex-col items-center text-xs text-gray3 my-3.5 md:my-2
    }
.OptionsActive{
      @apply  flex flex-col items-center text-xs text-blue2 my-3.5 md:my-2
    
    }
.IconDefault{
      @apply  p-1 bg-white rounded-full
    }
.IconActive{
      @apply p-1.5
    }
.fatherIconDefault{
      @apply  border-2 border-solid border-gray1 rounded-full
    }
.underlinedefault{
      @apply w-full h-1 bg-gray2 rounded-t-lg peer-hover:bg-blue2
    }
.underlineactive{
      @apply w-full h-1 bg-blue2 rounded-t-lg peer-hover:bg-blue2
    }
section.layout {
	@apply grid grid-cols-12

}
section.layout aside {
	@apply col-span-2
}
section.layout section {
	@apply col-span-12 md:col-span-10
}
.scrollbar-hide::-webkit-scrollbar {
    display: none !important;
}
.profile_cv{
	@apply gap-0 md:gap-9 mb-11
}
.profile_cv .cv_item_wrapper {
	@apply flex flex-col-reverse md:flex-col
}
.profile_cv .cv_item_wrapper h6 {
	@apply font-medium text-base leading-5 mb-6 text-blue3
}
.profile_cv .cv_item_wrapper .add_new_cv_info_button{
	@apply flex items-center border border-dashed border-blue2 rounded-lg p-3 text-base font-normal leading-5 text-gray4 w-full md:mx-0
}
.profile_cv .cv_item_wrapper .add_new_cv_info_button svg {
	@apply ml-2
}
.profile_cv .cv_item_wrapper .cv_completed_item {
	@apply flex items-start justify-between bg-bgItem md:bg-gray1 md:items-center rounded-lg py-2 px-4 my-3
}
.profile_cv .cv_item_wrapper .cv_completed_item h6 {
	@apply mb-0 font-normal text-xs leading-4 text-blue3
}
.profile_cv .cv_item_wrapper .cv_completed_item .btns {
	@apply flex gap-1.5
}
section.layout  section .layout_content {
	@apply overflow-hidden bg-gray5 border border-solid border-divid rounded-lg md:rounded-xxxxl mb-44 mt-10 mx-6 md:mx-0 md:mb-12 md:mt-0 md:bg-bgwhite md:pt-8 md:pr-28 md:pb-20 md:pl-24 lg:pl-32
}
header {
	@apply bg-bgwhite border border-solid border-divid rounded-b-xxxxl pt-5 px-5 pb-0 mb-8
}
header .profile_dropDown__btns {
	@apply flex items-center lg:justify-end
}
header .profile_dropDown__btns .search_box {
	@apply ml-5 relative  
}
header .profile_dropDown__btns .search_box ::after{
	@apply absolute border-l border-solid border-divid -left-2.5 top-0 h-full inline-block content-none
}
header .profile_dropDown__btns .search_box span {
	@apply absolute left-2 top-header transform -translate-y-1/2
}
header .profile_dropDown__btns .search_box span svg path {
	@apply  transition duration-300 hover:stroke-blue3
}
header .profile_dropDown__btns .search_box input {
	@apply w-60 h-9 rounded-xl bg-transparent
}
header .profile_dropDown__btns .search_box input.disable{
	@apply w-9 border-divid
}
header .profile_dropDown__btns button{
	@apply border border-solid border-f3 rounded-xl w-9 h-9 flex items-center justify-center
}
header .profile_dropDown__btns .notification {
	@apply ml-5 relative
}
header .profile_dropDown__btns .notification ::after{
	@apply absolute border-l border-solid border-divid left-2.5 top-0 content-none h-full inline-block
}
header .profile_dropDown__btns .notification_active {
	@apply relative
}
header .profile_dropDown__btns .notification_active ::before {
	@apply content-none absolute bg-red1 w-1.5 h-1.5 z-10 inline-block top-2 right-1.5 rounded-full border border-solid border-fff
}
header .profile_dropDown__btns .drop_down .img_name__arrow{
	@apply flex items-center
}
header .profile_dropDown__btns .drop_down .img_name__arrow .img__name {
	@apply flex items-center ml-5
}
header .profile_dropDown__btns .drop_down .img_name__arrow .img__name img{
	@apply w-7 h-7 ml-2.5
}
header .profile_dropDown__btns .drop_down .img_name__arrow .img__name span {
	@apply font-normal text-xs leading-3 text-black
}
header .nav_bar {
	@apply relative my-0 mx-auto
}
header .nav_bar nav ul {
	@apply flex items-center justify-center md:gap-2 lg:gap-4 xl:gap-7
}
header .nav_bar nav ul li a {
	@apply flex justify-center bg-fff rounded-lg min-w-140 md:min-w-90 lg:min-w-110 xl:min-w-140  py-1.5 px-0 font-normal text-sm leading-5 text-right text-gray4
}
header .nav_bar nav ul li.active a {
	@apply bg-lightblue1 text-blue3
}
header .nav_bar .progress_bar {
	@apply absolute -bottom-thpx w-full md:w-prog lg:w-full border-t-8 border-dashed border-gray2 self-center
}
header .nav_bar .progress_bar .blue_bar {
	@apply -top-2 right-0 flex absolute border-t-8 border-dashed border-blue2 transition-all duration-500
}
header .nav_bar .progress_bar .blue_bar ::after {
	@apply absolute -top-rochetH content-none w-rocket h-rocket bg-blue2 rounded-full -left-rocketL inline-block
}
header .nav_bar .progress_bar .rocket_icon {
	@apply absolute -left-leftRock w-10 h-10 rounded-full bg-rocket flex items-center justify-center -top-6
}
.modal_wrapper{
	@apply fixed z-9999 top-0 left-0 right-0 h-modalwrap grid bg-black bg-opacity-60 p-5
}
.modal_wrapper > div {
	@apply overflow-hidden bg-bgwhite rounded-lg h-fit mt-0 mb-10 mx-auto
}
.modal_wrapper > div .modal_header {
	@apply flex items-center justify-between py-6 px-9 bg-fff
}
.modal_wrapper > div .modal_header button {
	@apply h-4
}
.modal_wrapper > div .modal_header button svg {
	@apply h-4 w-4
}
.modal_wrapper > div .modal_header button svg path {
	@apply fill-blue3
}
.modal_wrapper > div .modal_header h3 {
	@apply font-bold text-base leading-4 text-blue3
}
.modal_wrapper > div .modal_content {
	@apply overflow-auto h-modalContent ltrText
}
.modal_wrapper > div .modal_content > div {
	@apply  rtlText
}
.modal_wrapper > div .modal_content > section {
	@apply  rtlText
}
.modal_wrapper > div ::-webkit-scrollbar {
	@apply w-4
}
.modal_wrapper > div ::-webkit-scrollbar-track {
	@apply bg-scroll
}
.modal_wrapper > div ::-webkit-scrollbar-thumb {
	@apply bg-gray4 rounded-hundred
} 
.profile_ability {
	@apply grid p-4 md:p-0
}
.profile_ability .choose_ability_box{
	@apply mt-7 grid gap-6 grid-cols-12
}
.profile_ability .choose_ability_box .abilities__select_box__title {
	@apply col-span-12 md:col-span-7
}
.profile_ability .choose_ability_box .abilities__select_box__title .search {
	@apply relative
}
.profile_ability .choose_ability_box .abilities__select_box__title .search label {
	@apply text-xs
}
.profile_ability .choose_ability_box .abilities__select_box__title .search input {
	@apply bg-gray1 border border-solid border-gray1 py-0 px-2
}
.profile_ability .choose_ability_box .abilities__select_box__title .search button {
	@apply absolute left-2 bottom-2 w-7 h-7 flex items-center justify-center bg-blue2 rounded-md
}
.profile_ability .choose_ability_box .abilities__select_box__title .search button svg path {
	@apply stroke-fff
}
.abilities__select_box{
	@apply mt-3 grid grid-cols-5 gap-3
}
.abilities__select_box 	> div{
	@apply gap-2 col-span-12 md:col-span-2
}
.abilities__select_box 	> div button {
	@apply w-full bg-white border-2 border-solid border-f3 rounded-lg font-normal text-sm leading-5 text-blue3 flex items-center
}
.abilities__select_box 	> div button :hover {
	@apply bg-mwhite border-2 border-solid border-blue2
}
.abilities__select_box ul {
	@apply py-4 px-5 col-span-3 bg-white border-2 border-solid border-f3 rounded-lg h-hbox overflow-auto hidden md:block
}
.abilities__select_box ul li{
	@apply flex items-center justify-between py-2 px-3 rounded-md
}
.abilities__select_box ul li span{
	@apply flex font-normal text-xs leading-4 text-gray10
}
.abilities__select_box ul li span span {
	@apply text-blue3 ml-1
}
.abilities__select_box ul li button{
	@apply h-5 
}
.abilities__select_box ul li {
	@apply hover:bg-blueHover
}
.abilities__select_box ul li span{
	@apply hover:text-blue3
}
.abilities__select_box ::-webkit-scrollbar{
	@apply w-3
}
.abilities__select_box ::-webkit-scrollbar-track{
	@apply bg-gray1 rounded-l-xl rounded
}
.abilities__select_box ::-webkit-scrollbar-thumb{
	@apply bg-gray2 rounded-hundred
}
.selected_abilities{
 @apply  bg-white border-2 border-solid border-f3 rounded-xl col-span-12 md:col-span-5 py-4 px-3 mt-4 h-ability
}
.selected_abilities h6{
	@apply font-medium text-sm leading-5 text-blue3 mb-8
}
.selected_abilities ul li{
	@apply flex items-center w-fit min-w-140 py-1.5 px-1 font-normal text-xs leading-5 text-gray10 hover:bg-blueHover hover:text-blue3
}
.selected_abilities ul li button {
	@apply ml-2.5 h-5
}
.select_language_box{
	@apply my-8 mx-0
}
.select_language_box h6{
	@apply font-medium text-sm leading-5 text-blue3 mb-3
}
.select_language_box > div {
	@apply flex flex-col items-center max-w-full md:max-w-200 md:flex-row gap-4
}
.select_language_box > div  > div{
	@apply min-w-200
}
.select_language_box > div  button{
	@apply font-normal text-xs text-gray4 flex items-center whitespace-nowrap
}
.select_language_box > div  button svg{
	@apply mr-2.5
}
.edit_profile_box {
	@apply items-center gap-8 xl:gap-20
}
.edit_profile_box .image {
	@apply flex flex-shrink-0 flex-col items-center
}
.edit_profile_box .image .image_box {
	@apply relative mb-6
}
.edit_profile_box .image .image_box img {
	@apply border-8 border-solid border-redblack w-44 h-44 rounded-full
}
.edit_profile_box .image .image_box button {
	@apply w-10 h-10 bg-blue2 flex items-center justify-center absolute overflow-hidden rounded-full bottom-2.5 right-5
}
.edit_profile_box .image .image_box button input {
	@apply absolute w-full h-full cursor-pointer opacity-0
}
.edit_profile_box .image p{
	@apply font-normal mb-2 text-xs leading-4 text-center text-gray4 w-36
}
.edit_profile_box .enter_data_box {
	@apply w-full grid
}
.edit_profile_box .enter_data_box > div {
	@apply grid 
}
.edit_profile_box .enter_data_box > div h6 {
	@apply font-medium text-sm leading-5 text-black
}
.edit_profile_box .enter_data_box > div .two_input {
	@apply grid grid-cols-2 gap-14
}
.edit_profile_box .enter_data_box > div .radio_choose_box {
	@apply flex items-center
}
.edit_profile_box .enter_data_box > div .radio_choose_box h5 {
	@apply text-xs leading-4 text-right text-blue3 ml-8
}
.edit_profile_box .enter_data_box > div .radio_choose_box div{
	@apply flex gap-8
}
.edit_profile_box .enter_data_box > div .selected_items {
	@apply flex flex-wrap items-center gap-4
}
.edit_profile_box .enter_data_box > div .selected_items li {
	@apply hover:bg-WarnRed hover:text-white pt-0.5 pb-0 px-6 h-fit bg-gray2 rounded-three font-normal text-sm whitespace-nowrap leading-6 text-blue3 relative transition-all duration-300
}
.edit_profile_box .enter_data_box > div .selected_items li button{
	@apply hidden absolute left-2.5 top-1/2 -translate-y-1/2 
}

.profile_wrapper > .btns {
	@apply flex justify-end gap-4
}
.profile_wrapper > .btns button{
	@apply min-w-180 py-3 px-0 disabled:bg-gray1 disabled:border disabled:border-solid disabled:border-gray2 disabled:text-gray4
}
.profile_wrapper_modal_btn 	> .btns button {
	@apply min-w-160 text-sm
}
.custom_select_box {
	@apply relative
}
.custom_select_box .box_title {
	@apply leading-4 text-right text-blue3 mb-2 inline-block 
}
.custom_select_box .title {
	@apply bg-white border border-solid border-gray1 h-10 rounded-xl py-0 px-3 flex items-center justify-between text-xs leading-6 text-gray4
}
.custom_select_box .title span{
	@apply text-black
}

.custom_select_box .list_box ul {
	@apply bg-white shadow-select rounded-lg grid p-3.5
}
.custom_select_box .list_box ul li {
	@apply py-2 rounded-md text-gray10 cursor-default hover:bg-blueHover hover:text-black
}
input {
	@apply bg-white  border border-solid border-gray1 rounded-md h-10 placeholder:font-normal placeholder:text-xs placeholder:leading-6 placeholder:text-gray3
}
textarea{
	@apply bg-white p-2  border border-solid border-gray1 rounded-md h-10 placeholder:font-normal placeholder:text-xs placeholder:leading-6 placeholder:text-gray3
}
.custom_input_box {
	@apply grid
}
.custom_input_box label {
	@apply font-normal text-titleS leading-4 text-right text-blue3 mb-1.5
}
.custom_input_box > div{
	@apply w-full relative grid
}
.custom_input_box > div .right_icon{
	@apply  mt-1 top-1/2 absolute -translate-y-1/2 right-4
}
.custom_input_box > div .email_icon{
	@apply top-1/2 absolute -translate-y-1/2 left-4
}
.custom_radio_box {
	@apply inline-flex cursor-pointer
}
.custom_radio_box .text {
	@apply font-normal text-xs leading-5 text-blue3 ml-1.5
}
.custom_radio_box > div {
	@apply relative text-xl user-select-none -mb-1 h-4 w-4 bg-white border border-solid border-gray2 rounded-full
}
.custom_radio_box > div input {
	@apply absolute opacity-0 cursor-pointer h-0 w-0 
}
.custom_radio_box > div .checkmark {
	@apply absolute rounded-full top-[48%] left-[51%] -translate-x-[52%] -translate-y-[46%] w-full h-full after:content-none after:absolute after:hidden after:top-1/2 after:left-1/2 after:-translate-x-[52%] after:-translate-y-[46%] after:w-1 after:h-2
}
.custom_radio_box > div input :checked ~ .checkmark {
	@apply border-4 border-solid border-FocusB w-2 h-2 after:block
}
.custom_checkbox{
	@apply inline-flex cursor-pointer disabled:
}
.custom_checkbox .text {
	@apply font-normal text-xs leading-5 text-blue3 mt-2
}
.custom_checkbox > div {
	@apply relative text-xl user-select-none -mb-1 h-5 w-5 bg-white border border-solid border-gray2 rounded-full
}
.custom_checkbox > div input{
	@apply absolute opacity-0 cursor-pointer h-0 w-0
}
.custom_checkbox > div input :checked ~ .checkmark{
	@apply bg-FocusB w-5 h-5 after:block top-[7%] left-[63%] content-link
}
.custom_checkbox > div .checkmark {
	@apply absolute rounded-full top-[48%] left-[51%] -translate-x-1/2 -translate-y-1/2 w-full h-full after:absolute after:hidden after:top-1/2 after:left-1/2 after:-translate-x-[52%] after:-translate-y-[46%] after:w-1 after:h-2 after:content-none
} */
