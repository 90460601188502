.custom_checkbox.disabled {
	cursor: not-allowed !important;
	> div {
		background: #f2f2f3 !important;
		border: none;
	}

	.text {
		color: #95969d !important;
	}
}

.search_custom_input {
	display: grid;
	position: relative;
	ul {
		position: absolute;
		width: calc(100% - 28px);
		top: 100%;
		margin-top: 16px;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		padding: 14px;
		z-index: 9999999;

		li {
			button {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 9px;
				border-radius: 4px;
				width: 100%;
				&:hover {
					background: #eff6ff;
				}

				span {
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					color: #767b77;
					span {
						color: #1b3751;
					}
				}
			}
		}
	}
}

.phone_number_input {
	> div {
		direction: ltr;
		.PhoneInputCountry {
			position: absolute;
			left: 18px;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			padding-right: 11px;
			border-right: 2px solid #afb0b6;

			.PhoneInputCountryIcon {
				width: 20px;
				height: 20px;
				border-radius: 100%;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border: none !important;
				overflow: hidden;
				box-shadow: none !important;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.PhoneInputCountrySelectArrow {
				border: none !important;
				transform: none !important ;
				-webkit-transform: none !important ;
				-moz-transform: none !important ;
				-ms-transform: none !important ;
				-o-transform: none !important ;
				opacity: 1 !important;
				width: unset !important;
				height: unset !important;
				margin-left: 11px;
				&::after {
					content: url('../../assets/icons/selectBoxArrow.svg');
				}
			}
		}

		input {
			padding-left: 85px;
		}
	}
}

.custom_datepicker {
	.rmdp-input {
		background: #ffffff !important;
		border: 1px solid #f2f2f3 !important;
		border-radius: 6px !important;
		height: 40px !important;
		padding: 0 27px 0 8px !important;
		width: 100% !important;

		&::placeholder {
			font-weight: 400 !important;
			font-size: 12px !important;
			line-height: 24px !important;
			color: #afb0b6 !important;
		}
	}

	> div {
		> span {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
		}
		.arrow {
			left: 11px;
		}
		.calender {
			top: 53%;
			right: 8px;
		}
	}
}